import Vue from "vue";
import Vuex from "vuex";

import { alert } from "./browser/alert";
import { user } from "./user/user";

Vue.use(Vuex);

export default new Vuex.Store({
  actions: {},
  modules: {
    alert,
    user,
  },
});
