import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// import Root from "../views/Root.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Root",
  //   component: Root
  // },
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/portal/set-pass/:userId/:token",
    name: "PasswordReset",
    component: () => import(/* webpackChunkName: "team" */ "../components/portal/PasswordReset.vue")
  },
  {
    path: "/portal/signin",
    name: "SignIn",
    component: () => import(/* webpackChunkName: "team" */ "../components/portal/SignIn.vue")
  },
  {
    path: "/portal/project",
    name: "PortalProject",
    component: () => import(/* webpackChunkName: "team" */ "../components/portal/PortalProject.vue")
  },
  {
    path: "/team/:teammate",
    name: "Team",
    component: () => import(/* webpackChunkName: "team" */ "../views/Team.vue")
  },
  {
    path: "/services",
    name: "Services",
    component: () =>
      import(/* webpackChunkName: "services" */ "../views/Services.vue")
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
