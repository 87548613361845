import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

let inMemoryToken = null; // eslint-disable-line no-unused-vars

const EXPIRATION = 60 * 15 * 1000;

// axios.defaults.withCredentials = true;

export const setTokenHeader = (token) => {
  // export function setTokenHeader(token) {
  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common["Authorization"];
  }
};

export const setShortToken = async (token) => {
  if (token) {
    let now = Date.now();

    inMemoryToken = {
      token: token,
      expiry: now + EXPIRATION,
    };
    setTokenHeader(token);
  } else {
    inMemoryToken = null;
    setTokenHeader();
  }
};

// const validateToken = async () => {
//
//   let token = inMemoryToken;
//   let now = Date.now();
//
//   if(inMemoryToken.expiry < now){
//    // // TODO: if token is expired
//    // // TODO: 1. get new token => let creadential = await getNewToken()
//    // // TODO: 2. set new token => setShortToken(credential)
// }
//   return;
// }

export async function apiCall(method, path, data) {
  // // TODO: VALIDATE TOKEN
  // await validateToken();

  return new Promise((resolve, reject) => {
    return axiosInstance[method](path, data)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err.response);
      });
  });
}


export async function axiosCall(method, path, data) {
  // // TODO: VALIDATE TOKEN
  // await validateToken();

  return new Promise((resolve, reject) => {
    return axios[method](path, data)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err.response);
      });
  });
}


export function getWithOptionsApiCall(path, options) {
  return new Promise((resolve, reject) => {
    return axiosInstance
      .get(path, options)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err.response);
      });
  });
}

export function withOptionsApiCall(method, path, data, options) {
  return new Promise((resolve, reject) => {
    return axiosInstance[method](path, data, options)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err.response);
      });
  });
}

// const ensureTokenValidation = async () => {
//   const store = accessStore();
//   if (store !== undefined) {
//     let token = store.getState().token;
//
//     // if(token)
//     // console.log(user);
//   } else {
//     return;
//   }
// };
