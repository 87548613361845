<template>
  <div class="navbar" :class="{ hidden: isHidden }">
    <h3 class="alpha-title side-section" @click="navTo('/')">allpha</h3>
    <div v-if='isAuthenticated===true' class="inner-routes">
      <h4 :class='{ selected: actualRoute==="/portal/project"}' @click='navTo(`/portal/project`)'>Projets</h4>
      <!-- <h4 :class='{ selected: actualRoute==="ambassador"}' @click='navTo(`/portal/ambassador`)'>Ambassadeur</h4> -->
    </div>

    <div class="vert-cont side-section" @click='logUserOut' v-if='isAuthenticated===true'>
      <h5 class='user-name' >{{ currentUser.user.customerName }}</h5>
      <h5>Déconnection</h5>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "Navbar",
  methods: {
    navTo(to) {
      this.$router.push(to);
    },
    logUserOut(){
      this.logOut();
      this.$router.push('/');
    },
    ...mapActions({
      logOut: 'user/logOut'
    })
  },
  computed: {
    actualRoute(){
      return this.$route.fullPath;
    },
    isHidden() {
      // return this.$route.fullPath === "/" || this.$route.fullPath === "/home";
      return this.$route.fullPath === "/";
    },
    ...mapGetters({
      isAuthenticated: 'user/isAuthenticated',
      currentUser: 'user/getCurrentUser'
    })
  }
};
</script>
