<template>
  <div class="img-preview">
    <transition appear name="preview-transition" mode="out-in">
      <img :key="data.url" :src="data.url" />
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    data: Object
  }
};
</script>
