<template>
  <div id="app">
    <Navbar />
    <transition appear name="route-transition" mode="out-in">
      <router-view
        :setModal="setModal"
        :initApp="initApp"
        :appInitiated="appInitiated"
        :currentUser='currentUser'
        :setAlert='setAlert'
      />
    </transition>

    <transition name="modal-transition" mode="out-in">
      <Modal
        v-if="index !== null"
        :list="list"
        :index="index"
        :navPortfolio="navPortfolio"
        :closeModal="closeModal"
      />
    </transition>
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Modal from "./components/Modal.vue";

import * as dayjs from 'dayjs';
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

import { recoverUserSession } from '@/apiCall';

import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Navbar,
    Modal
  },
  data() {
    return {
      list: [],
      index: null,
      appInitiated: false
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/getCurrentUser'
    })
  },
  methods: {
    initApp() {
      this.appInitiated = true;
    },
    setModal(data) {
      this.list = data.list;
      this.index = data.index;
    },
    navPortfolio(nextInd) {
      this.index = nextInd;
    },
    closeModal() {
      this.index = null;
      this.list = [];
    },
    async recoverSession(token){
      try {

        let { userInfo } = await recoverUserSession({token})

        this.setCurrentUser(userInfo)

        this.$router.push(`/portal/project`)

      } catch (e) {
        console.log(e)
      }
    },
    ...mapActions({
      setAlert: 'alert/setAlert',
      setCurrentUser: 'user/setCurrentUser'
    })
  },
  mounted(){
    let token = localStorage.getItem('allpha_token');
    if(token!==null){
      this.recoverSession(token)
    }
    // else if(!this.$route.params.email) {
    //   this.$router.push('/')
    // }
  }
};
</script>

<style lang="scss">
@import "./App.css";
</style>
