<template>
  <div class="modal">
    <FontAwesomeIcon class="close-icon" icon="times" @click="closeModal" />

    <FontAwesomeIcon
      v-if="index > 0"
      class="nav-icon left"
      :class="{ disabled: false }"
      icon="chevron-left"
      @click="nav(-1)"
    />

    <transition name="preview-transition" mode="out-in">
      <component :is="modal" :index="index" :data="data"></component>
    </transition>

    <FontAwesomeIcon
      v-if="list.length - 1 < index"
      class="nav-icon right"
      :class="{ disabled: false }"
      icon="chevron-right"
      @click="nav(1)"
    />
  </div>
</template>

<script>
import ImagePreview from "./preview/ImagePreview.vue";
import VideoPreview from "./preview/VideoPreview.vue";

export default {
  components: {
    ImagePreview,
    VideoPreview
  },
  props: {
    list: Array,
    index: Number,
    navPortfolio: Function,
    closeModal: Function
  },
  computed: {
    modal() {
      let m;
      if (this.index !== null) {
        switch (this.list[this.index].type) {
          case "image":
            m = "ImagePreview";
            break;

          case "video":
            m = "VideoPreview";
            break;

          default:
        }
      }
      return m;
    },
    data() {
      let data;
      if (this.index !== null) {
        data = this.list[this.index];
      }
      return data;
    }
  },
  methods: {
    nav(ind) {
      if (
        (ind === -1 && this.index === 0) ||
        (ind === 1 && this.index + 1 === this.list.length)
      ) {
        return;
      } else {
        let nextInd = this.index + ind;
        this.navPortfolio(nextInd);
      }
    }
  }
};
</script>
