const DEFAUT_STATE = {
  isAuthenticated: false,
  user: {},
};

export const user = {
  namespaced: true,
  state() {
    return DEFAUT_STATE;
  },
  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    getCurrentUser(state) {
      return state;
    },
  },
  mutations: {
    SET_CURRENT_USER(state, data) {
      state.isAuthenticated = true;
      state.user = data;
    },
    LOG_OUT(state) {
      state.isAuthenticated = false;
      state.user = {};
    },
  },
  actions: {
    setCurrentUser({ commit }, data) {
      commit("SET_CURRENT_USER", data);
    },
    logOut({ commit }) {
      localStorage.removeItem('allpha_token');
      commit("LOG_OUT");
    },
  },
};
