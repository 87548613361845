<template>
  <div class="realis-section">
    <FontAwesomeIcon
      class="nav-icon left"
      icon="chevron-left"
      @click="navProjects(-1)"
    />
    <FontAwesomeIcon
      class="nav-icon right"
      icon="chevron-right"
      @click="navProjects(1)"
    />

    <transition name="preview-transition" mode="out-in">
      <img :key="proj.cover.url" class="main-img" :src="proj.cover.url" />
    </transition>

    <!-- <p>
      {{proj.description}}
    </p> -->

    <transition name="preview-transition" mode="out-in">
      <div :key="proj.title" class="overlay" :class='projectClass'>

        <div class="title-desc-cont">
          <img class='logo' v-if='proj.logo' :src="proj.logo" />
          <h2 class='title' v-else>{{ proj.title }}</h2>
          <!-- <div class=""> -->
            <p class=''>{{ proj.description }}</p>
            <!-- <button @click=''>GO!</button> -->
          <!-- </div> -->
        </div>

        <div class="display-cont">
          <img class='one' :src="proj.media[0].url" />

          <div class="vid-cont mob" @click="openVideo({ list: [proj.video], index: 0 })" >
            <FontAwesomeIcon class='play-icon' icon='play'></FontAwesomeIcon>
            <img class='vid-placeholder mob' :src="proj.vidCover"/>
          </div>

          <img class='three' :src="proj.media[1].url" />
        </div>


        <!-- <video
        playsinline
        autoplay
        loop
        muted
        @click="openVideo({ list: [proj.video], index: 0 })"
        >
        <source :src="proj.video.url" type="video/mp4" />
        Your browser does not support the video tag.
      </video> -->
      </div>
    </transition>
  </div>
</template>

<script>
import portfolio from "../mediaSrc/portfolio.js";

export default {
  props: {
    setModal: Function,
    toggleAudio: Function,
    closeAudio: Function
  },
  data() {
    return {
      index: 0
    };
  },
  computed: {
    proj() {
      return portfolio.marketing[this.index];
    },
    projectClass(){
      let cl;
      switch (this.index) {
        case 0:
          cl = 'pat';
          break;

        case 1:
          cl = 'chalet';
          break;

        case 2:
          cl = 'alpha';
          break;
        default:
        cl = 'pat';
      }
      return cl;
    }
  },
  methods: {
    openVideo(data) {
      this.closeAudio();
      this.setModal(data);
    },
    navProjects(inc) {
      if (inc === -1 && this.index === 0) {
        this.index = portfolio.marketing.length - 1;
      } else if (portfolio.marketing.length === this.index + inc) {
        this.index = 0;
      } else {
        this.index = this.index + inc;
      }
    }
  }
};
</script>
