export default {
  sam: [
    {
      type: "image",
      url:
        "https://allpha.s3.ca-central-1.amazonaws.com/app/sam/P1011538.00_02_22_00.Still010.jpg"
    },
    {
      type: "image",
      url: "https://allpha.s3.ca-central-1.amazonaws.com/app/sam/P1022454.jpg"
    },

    {
      type: "image",
      url: "https://allpha.s3.ca-central-1.amazonaws.com/app/sam/P1022449.jpg"
    },
    // {
    //   type: "image",
    //   url: "https://allpha.s3.ca-central-1.amazonaws.com/app/sam/P1022653.jpg"
    // },
    {
      type: "image",
      url: "https://allpha.s3.ca-central-1.amazonaws.com/app/sam/P1022747.jpg"
    },
    {
      type: "image",
      url: "https://allpha.s3.ca-central-1.amazonaws.com/app/sam/P1022720.jpg"
    },
    {
      type: "image",
      url: "https://allpha.s3.ca-central-1.amazonaws.com/app/sam/P1022701.jpg"
    },
    // {
    //   type: "image",
    //   url: "https://allpha.s3.ca-central-1.amazonaws.com/app/sam/P1000793-2.jpg"
    // },
    {
      type: "image",
      url: "https://allpha.s3.ca-central-1.amazonaws.com/app/sam/P1011711.jpg"
    },
    {
      type: "image",
      url:
        "https://allpha.s3.ca-central-1.amazonaws.com/app/sam/Promo_Wide_en.00_00_17_10.Still001.jpg"
    }
    // {
    //   type: "image",
    //   url:
    //     "https://allpha.s3.ca-central-1.amazonaws.com/app/sam/NINJA_1_S001_S001_T004.00_03_47_12.Still002.jpg"
    // }
  ],
  jm: [
    {
      type: "image",
      url: "https://allpha.s3.ca-central-1.amazonaws.com/app/jm/JMB01997.jpg"
    },
    {
      type: "image",
      url: "https://allpha.s3.ca-central-1.amazonaws.com/app/jm/Day374.jpg"
    },

    {
      type: "image",
      url: "https://allpha.s3.ca-central-1.amazonaws.com/app/jm/Day3211.jpg"
    },
    {
      type: "image",
      url: "https://allpha.s3.ca-central-1.amazonaws.com/app/jm/Day3316.jpg"
    },
    {
      type: "image",
      url: "https://allpha.s3.ca-central-1.amazonaws.com/app/jm/Day360.jpg"
    },
    {
      type: "image",
      url: "https://allpha.s3.ca-central-1.amazonaws.com/app/jm/JMB06693.jpg"
    },
    {
      type: "image",
      url: "https://allpha.s3.ca-central-1.amazonaws.com/app/jm/JMB06711.jpg"
    },
    {
      type: "image",
      url:
        "https://allpha.s3.ca-central-1.amazonaws.com/app/jm/JMB07636-Edit.jpg"
    },
    {
      type: "image",
      url: "https://allpha.s3.ca-central-1.amazonaws.com/app/jm/JMB07656.jpg"
    },
    {
      type: "image",
      url:
        "https://allpha.s3.ca-central-1.amazonaws.com/app/jm/JMB08183+copy-2.jpg"
    },
    {
      type: "image",
      url: "https://allpha.s3.ca-central-1.amazonaws.com/app/jm/JMB07793.jpg"
    },
    {
      type: "image",
      url: "https://allpha.s3.ca-central-1.amazonaws.com/app/jm/JMB08231.jpg"
    },
    {
      type: "image",
      url: "https://allpha.s3.ca-central-1.amazonaws.com/app/jm/JMB00960.jpg"
    },
    {
      type: "image",
      url: "https://allpha.s3.ca-central-1.amazonaws.com/app/jm/JMB09201.jpg"
    },
    {
      type: "image",
      url: "https://allpha.s3.ca-central-1.amazonaws.com/app/jm/JMB09494.jpg"
    },
    {
      type: "image",
      url: "https://allpha.s3.ca-central-1.amazonaws.com/app/jm/JMB09620.jpg"
    },
    {
      type: "image",
      url: "https://allpha.s3.ca-central-1.amazonaws.com/app/jm/JMB09684.jpg"
    },
    {
      type: "image",
      url:
        "https://allpha.s3.ca-central-1.amazonaws.com/app/jm/JMB08055+copy.jpg"
    },
    {
      type: "image",
      url:
        "https://allpha.s3.ca-central-1.amazonaws.com/app/jm/JMB07916-Edit+copy.jpg"
    },
    {
      type: "image",
      url: "https://allpha.s3.ca-central-1.amazonaws.com/app/jm/JMB07481.jpg"
    },
    {
      type: "image",
      url: "https://allpha.s3.ca-central-1.amazonaws.com/app/jm/JMB07180.jpg"
    },
    {
      type: "image",
      url:
        "https://allpha.s3.ca-central-1.amazonaws.com/app/jm/JMB07955-Edit+copy.jpg"
    }
  ],
  marketing: [
    {
      title: 'Les Bols à Pat',
      color: 'white',
      web: 'https://www.lenikoli.com/',
      description: "Publicité vidéo et vidéo corporatif pour Les Bols à Pat",
      vidCover: "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/bols_a_pat/Allpha.00_00_23_19.Still008.jpg",
      cover: {
        type: "image",
        url:
          "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/bols_a_pat/Allpha.00_00_18_20.Still006.jpg"
      },
      media: [
        {
          type: "image",
          url:
            "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/bols_a_pat/Allpha.00_00_21_23.Still001.jpg"
        },
        {
          type: "image",
          url:
            "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/bols_a_pat/Allpha.00_00_18_20.Still006.jpg"
        },
        {
          type: "image",
          url:
            "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/bols_a_pat/Allpha.00_00_23_06.Still007.jpg"
        },
        {
          type: "image",
          url:
            "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/bols_a_pat/Allpha.00_00_23_19.Still008.jpg"
        }
      ],
      video: {
        type: "video",
        url:
          "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/bols_a_pat/EntrevueFinale.mov"
      }
    },
    {
      title: 'Chalet la falaise',
      color: 'rgb(232, 10, 70)',
      web: 'https://www.airbnb.ca/rooms/43740757?locale=en&_set_bev_on_new_domain=1645307303_NDljMjRhYTc5YzNh&source_impression_id=p3_1645307304_CFZ%2BU8xlvyU%2Fwcat',
      vidCover: "https://allpha.s3.ca-central-1.amazonaws.com/src/RBnB/_1001363-min.jpg",
      description: "Vidéo pour fin promotionnelle du superbe RBnB Chalet la falaise disponible au liens...",
      cover: {
        type: "image",
        url:
          "https://allpha.s3.ca-central-1.amazonaws.com/src/RBnB/_1001374-min.jpg"
      },
      media: [
        {
          type: "image",
          url:
            "https://allpha.s3.ca-central-1.amazonaws.com/src/RBnB/_1001766-min.jpg"
        },
        {
          type: "image",
          url:
            "https://allpha.s3.ca-central-1.amazonaws.com/src/RBnB/_1001791-min.jpg"
        },
        {
          type: "image",
          url:
            "https://allpha.s3.ca-central-1.amazonaws.com/src/RBnB/_1001449-min.jpg"
        },
        {
          type: "image",
          url:
            "https://allpha.s3.ca-central-1.amazonaws.com/src/RBnB/_1001363-min.jpg"
        },
        {
          type: "image",
          url:
            "https://allpha.s3.ca-central-1.amazonaws.com/src/RBnB/_1001784-min.jpg"
        }
      ],
      video: {
        type: "video",
        url:
          "https://allpha.s3.ca-central-1.amazonaws.com/src/RBnB/JM_SHORT.mp4"
      }
    },
    {
      title: 'La micro Alpha',
      color: 'rgb(31, 31, 31)',
      web: 'https://boutique.brasseriealpha.com/',
      vidCover: "https://allpha.s3.ca-central-1.amazonaws.com/src/brasserieAlpha/Screen+Shot+2022-02-19+at+3.43.06+PM.jpg",
      description: "Vidéo promotionnelle des installations de la microbrasserie Alpha de Québec. Brasserie Alpha est située à Québec et se spécialise dans le brassage de bières houblonnées. Disponible actuellement en growler de 1L pour apporter à la maison!",
      cover: {
        type: "image",
        url:
          "https://allpha.s3.ca-central-1.amazonaws.com/src/brasserieAlpha/_1013028-min.jpg"
      },
      logo: 'https://allpha.s3.ca-central-1.amazonaws.com/src/brasserieAlpha/Logo-Alpha-sans-Est-Vectoriel.svg',
      media: [
        {
          type: "image",
          url:
          "https://allpha.s3.ca-central-1.amazonaws.com/src/brasserieAlpha/_1013161-min.jpg"
        },
        {
          type: "image",
          url:
            "https://allpha.s3.ca-central-1.amazonaws.com/src/brasserieAlpha/_1013044-min.jpg"
        },
        {
          type: "image",
          url:
            "https://allpha.s3.ca-central-1.amazonaws.com/src/brasserieAlpha/_1013022-min.jpg"
        },
        {
          type: "image",
          url:
            "https://allpha.s3.ca-central-1.amazonaws.com/src/brasserieAlpha/_1012994-min.jpg"
        }
      ],
      video: {
        type: "video",
        url:
          "https://allpha.s3.ca-central-1.amazonaws.com/src/brasserieAlpha/Alpha_promo.mp4"
      }
    },
    // {
    //   textTitle: 'Les bols à Pat',
    //   title:
    //     "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/the_runner/TheRunnertitle+copy.png",
    //   description: "Publicité vidéo créée pour la plateforme AXYO",
    //   cover: {
    //     type: "image",
    //     url:
    //       "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/the_runner/LV+-+FR.00_00_55_15.Still017.jpg"
    //   },
    //   media: [
    //     {
    //       type: "image",
    //       url:
    //         "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/the_runner/LV+-+FR.00_00_05_23.Still003.jpg"
    //     },
    //     {
    //       type: "image",
    //       url:
    //         "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/the_runner/LV+-+FR.00_00_10_11.Still005.jpg"
    //     },
    //     {
    //       type: "image",
    //       url:
    //         "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/the_runner/LV+-+FR.00_00_20_12.Still011.jpg"
    //     },
    //     {
    //       type: "image",
    //       url:
    //         "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/the_runner/LV+-+FR.00_01_00_21.Still018.jpg"
    //     }
    //   ],
    //   video: {
    //     type: "video",
    //     url:
    //       "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/the_runner/SV+-+FR+-+Mus_2.mp4"
    //   }
    // }
  //   {
  //     title:
  //       "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/guitare/GuitarVibes.png",
  //     description: "Publicité vidéo créée pour la plateforme AXYO",
  //     cover: {
  //       type: "image",
  //       url:
  //         "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/guitare/Guitar.00_00_06_02.Still004.jpg"
  //     },
  //     media: [
  //       {
  //         type: "image",
  //         url:
  //           "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/guitare/Allpha.00_01_55_19.Still003.jpg"
  //       },
  //       {
  //         type: "image",
  //         url:
  //           "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/guitare/Allpha.00_02_00_01.Still004.jpg"
  //       },
  //       {
  //         type: "image",
  //         url:
  //           "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/guitare/Allpha.00_02_14_08.Still005.jpg"
  //       },
  //       {
  //         type: "image",
  //         url:
  //           "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/guitare/Guitar.00_00_19_02.Still007.jpg.jpg"
  //       },
  //       {
  //         type: "image",
  //         url:
  //           "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/guitare/Guitar.00_00_00_07.Still001.jpg"
  //       },
  //       {
  //         type: "image",
  //         url:
  //           "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/guitare/Guitar.00_00_14_19.Still006.jpg"
  //       },
  //       {
  //         type: "image",
  //         url:
  //           "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/guitare/Guitar.00_00_06_02.Still004.jpg"
  //       }
  //     ],
  //     video: {
  //       type: "video",
  //       url:
  //         "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/guitare/Guitar.m4v"
  //     }
  //   },
  //   {
  //     title:
  //       "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/promo_axyo/AXYO.png",
  //     description: "Un petit teaser de la plateforme AXYO ;)",
  //     cover: {
  //       type: "image",
  //       url:
  //         "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/promo_axyo/Promo_Wide_en.00_00_17_10.Still001.jpg"
  //     },
  //     media: [
  //       {
  //         type: "image",
  //         url:
  //           "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/promo_axyo/Promo_Wide_en.00_00_17_10.Still001.jpg"
  //       },
  //       {
  //         type: "image",
  //         url:
  //           "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/promo_axyo/Promo_Wide_en.00_00_00_23.Still006.jpg"
  //       },
  //       {
  //         type: "image",
  //         url:
  //           "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/promo_axyo/Promo_Wide_en.00_00_06_12.Still005.jpg"
  //       },
  //       {
  //         type: "image",
  //         url:
  //           "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/promo_axyo/Promo_Wide_en.00_00_11_04.Still004.jpg"
  //       },
  //       {
  //         type: "image",
  //         url:
  //           "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/promo_axyo/Promo_Wide_en.00_00_13_07.Still003.jpg"
  //       },
  //       {
  //         type: "image",
  //         url:
  //           "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/promo_axyo/Promo_Wide_en.00_00_37_20.Still002.jpg"
  //       }
  //     ],
  //     video: {
  //       type: "video",
  //       url:
  //         "https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/promo_axyo/AXYO_Premier-1.mov"
  //     }
  //   },
  //   {
  //     title:
  //       "https://allpha.s3.ca-central-1.amazonaws.com/app/prod/clinique/Max+flex.png",
  //     description:
  //       "Capsule web demontrant l importance et les methodes detirement",
  //     cover: {
  //       type: "image",
  //       url:
  //         "https://allpha.s3.ca-central-1.amazonaws.com/app/prod/clinique/E%CC%81tirementfinal+-+HD+1080p.00_00_21_11.Still001.jpg"
  //     },
  //     media: [
  //       {
  //         type: "image",
  //         url:
  //           "https://allpha.s3.ca-central-1.amazonaws.com/app/prod/clinique/E%CC%81tirementfinal+-+HD+1080p.00_00_57_09.Still002.jpg"
  //       },
  //       {
  //         type: "image",
  //         url:
  //           "https://allpha.s3.ca-central-1.amazonaws.com/app/prod/clinique/E%CC%81tirementfinal+-+HD+1080p.00_03_48_17.Still004.jpg"
  //       },
  //       {
  //         type: "image",
  //         url:
  //           "https://allpha.s3.ca-central-1.amazonaws.com/app/prod/clinique/E%CC%81tirementfinal+-+HD+1080p.00_01_45_08.Still003.jpg"
  //       },
  //       {
  //         type: "image",
  //         url:
  //           "https://allpha.s3.ca-central-1.amazonaws.com/app/prod/clinique/E%CC%81tirementfinal+-+HD+1080p.00_00_21_11.Still001.jpg"
  //       }
  //     ],
  //     video: {
  //       type: "video",
  //       url:
  //         "https://allpha.s3.ca-central-1.amazonaws.com/app/prod/clinique/E%CC%81tirementfinal+-+HD+1080p.mp4"
  //     }
  //   }
  ],
  // production: [
  //   {
  //     title: "L'étirement, c'est important",
  //     description:
  //       "Capsule web demontrant l importance et les methodes detirement",
  //     cover: {
  //       type: "image",
  //       url:
  //         "https://allpha.s3.ca-central-1.amazonaws.com/app/prod/clinique/E%CC%81tirementfinal+-+HD+1080p.00_00_21_11.Still001.jpg"
  //     },
  //     media: [
  //       {
  //         type: "image",
  //         url:
  //           "https://allpha.s3.ca-central-1.amazonaws.com/app/prod/clinique/E%CC%81tirementfinal+-+HD+1080p.00_00_57_09.Still002.jpg"
  //       },
  //       {
  //         type: "image",
  //         url:
  //           "https://allpha.s3.ca-central-1.amazonaws.com/app/prod/clinique/E%CC%81tirementfinal+-+HD+1080p.00_03_48_17.Still004.jpg"
  //       },
  //       {
  //         type: "image",
  //         url:
  //           "https://allpha.s3.ca-central-1.amazonaws.com/app/prod/clinique/E%CC%81tirementfinal+-+HD+1080p.00_01_45_08.Still003.jpg"
  //       },
  //       {
  //         type: "image",
  //         url:
  //           "https://allpha.s3.ca-central-1.amazonaws.com/app/prod/clinique/E%CC%81tirementfinal+-+HD+1080p.00_00_21_11.Still001.jpg"
  //       }
  //     ]
  //   }
  // ]
};
