<template>
  <div ref="main" class="home" :class="{ reveal: showMenu }">
    <div id="home" class="welcome-cont">
      <FontAwesomeIcon
        class="mute-icon"
        v-if="isAudioPlaying"
        @click="toggleAudio"
        icon="volume-mute"
      />
      <FontAwesomeIcon
        class="mute-icon"
        v-else
        @click="toggleAudio"
        icon="volume-up"
      />

      <div class="landing" v-if="appInitiated === false">
        <transition appear name="long-transition">
          <div class="welcome-msg" @click="acceptInitiation">
            <h2>
              bienvenue
            </h2>
            <h3>
              cliquez ici pour débuter
            </h3>
          </div>
        </transition>
      </div>

      <div class="main-title">
        <h4>digital</h4>
        <h2 class="alpha-title">allpha</h2>
        <h5>par AXYO</h5>
      </div>
    </div>

    <div
      class="allpha-info"
      :class="pos > infoPos - 200 ? 'reveal' : ''"
      ref="info"
    >
      <img
        class="back-logo"
        src="https://allpha.s3.ca-central-1.amazonaws.com/src/Group+4881.png"
      />

      <div class="overlay">
        <h2 class="alpha-title">allpha</h2>
        <p>
          Allpha est LE nouveau service de production digitale. Il vous permet
          de créer tous vos produits audiovisuels à des fins marketing, ou
          autres…
          <br />
          <br />
          Allpha est une branche d'AXYO Divertissement, qui offrira sous peu la
          plateforme AXYO, une communauté digitale dédiée au divertissement.
        </p>
      </div>
    </div>

    <div
      class="mkt-sec section"
      :class="pos > servPos - 600 ? 'reveal' : ''"
      ref="service"
    >
      <img
        src="https://allpha.s3.ca-central-1.amazonaws.com/app/jm/JMB07656.jpg"
      />

      <div class="section-info">
        <h2 class="alpha-title">marketing</h2>
        <p>
          Nos produits marketing sont innovants et soignés. Ils sauront vous
          plaire à coup sûr!
        </p>
        <h5 class="prod-type one">Photos / posters</h5>
        <h5 class="prod-type two">Videos publicitaires</h5>
        <h5 class="prod-type three">Videos corporatifs</h5>
        <!-- <h5 class='prod-type four'>Banque d images pour publication</h5> -->
      </div>
    </div>

    <div class="prod-sec section" :class="pos > servPos ? 'reveal' : ''">
      <img
        src="https://allpha.s3.ca-central-1.amazonaws.com/app/jm/JMB08055+copy.jpg"
      />

      <div class="section-info">
        <h2 class="alpha-title">production</h2>
        <p>
          Pour tous vos produits multimédias à vocation autre que publicitaire…
        </p>
        <h5 class="prod-type one">Shooting photo corporatif</h5>
        <h5 class="prod-type two">Projets personnels</h5>
        <h5 class="prod-type three">Capsules vidéo</h5>
        <h5 class="prod-type four">Mini-séries</h5>
        <h5 class="prod-type five">Bref, pas mal tout...!</h5>
      </div>
    </div>

    <div
      class="team-section"
      :class="pos > teamPos - 600 ? 'reveal' : ''"
      ref="team"
    >
      <div class="title">
        <h2 class="alpha-title white">a</h2>
        <h2 class="alpha-title">team</h2>
      </div>

      <div class="teammate sam">
        <img
          src="https://allpha.s3.ca-central-1.amazonaws.com/src/sam_profile.jpg"
        />
        <!-- <img
          src="https://allfredo.s3.ca-central-1.amazonaws.com/allpha/P1022428.jpg"
        /> -->
        <h3>Sam</h3>
        <div class="teammate-info">
          <p>
            J’ai toujours eu une passion pour le cinéma et la photographie.
            Cependant, c’est depuis peu que j’ai décidé de m’y mettre et
            d’expérimenter dans cet univers qui me passionne chaque jour un peu
            plus… De belles choses sont à venir ici!
          </p>
          <button class="portfolio-btn" @click="goTo('team/sam')">
            Portfolio
          </button>
        </div>
      </div>

      <div class="teammate jm" :class="pos > teamPos - 300 ? 'reveal' : ''">
        <img
          src="https://allpha.s3.ca-central-1.amazonaws.com/app/jm/Bannie%CC%80reJM-2.jpg"
        />
        <h3>JM</h3>
        <div class="teammate-info">
          <p>
            Depuis déjà quelques années, je poursuis cette passion qu’est la
            création de contenu photo ou vidéo. Ce qui me motive plus que tout,
            c’est de sortir des sentiers battus et de réinventer cet art à ma
            façon. Créer quelque chose de nouveau en suivant le fameux « think
            outside the box ».
          </p>
          <button class="portfolio-btn" @click="goTo('team/jm')">
            Portfolio
          </button>
        </div>
      </div>
    </div>

    <div
      class="realise"
      :class="pos > realisePos - 400 ? 'reveal' : ''"
      ref="realise"
    >
      <h2 class="alpha-title">realisations</h2>
      <Realisations :setModal="setModal" :toggleAudio="toggleAudio" :closeAudio='closeAudio' />
    </div>

    <div
      class="contact-section"
      :class="pos > contPos - 200 ? 'reveal' : ''"
      ref="contact"
    >
      <Contact />
    </div>
  </div>
</template>

<script>
import Contact from "./Contact.vue";
import Realisations from "../components/Realisations.vue";

export default {
  name: "Home",
  components: {
    Contact,
    Realisations
  },
  props: {
    setModal: Function,
    initApp: Function,
    appInitiated: Boolean
  },
  data() {
    return {
      ticking: false,
      pos: 0,
      animationInitiated: false,
      isAudioPlaying: false,
      volumeDowned: false,
      showMenu: false,
      videoReady: false,
      videoError: false,
      infoPos: 0,
      servPos: 0,
      teamPos: 0,
      realisePos: 0,
      contPos: 0
    };
  },
  methods: {
    goTo(to) {
      this.$router.push(`/${to}`);
    },
    // doSomething(pos) {
    //   console.log(pos);
    // },
    acceptInitiation() {
      this.initApp();
    },
    toggleAudio() {
      let mus = document.getElementsByClassName("mus")[0];
      if (this.isAudioPlaying === true) {
        mus.pause();
        this.isAudioPlaying = false;
      } else {
        mus.play();
        this.isAudioPlaying = true;
      }
    },
    closeAudio() {
      let mus = document.getElementsByClassName("mus")[0];
      mus.pause();
      this.isAudioPlaying = false;
    },
    selectAudio() {
      let random = Math.random() * 100;
      console.log(random);
      if (random < 25) {
        return "https://allpha.s3.ca-central-1.amazonaws.com/src/mus/Camaro+by+OliverMichael+Artlist.mp3";
      } else if (random > 25 && random < 50) {
        return "https://allpha.s3.ca-central-1.amazonaws.com/src/mus/arcade+by+generation-lost+Artlist.mp3";
      } else if (random > 50 && random < 75) {
        return "https://allpha.s3.ca-central-1.amazonaws.com/src/mus/Forever+by+SunnyFruit+Artlist.mp3";
      } else {
        return "https://allpha.s3.ca-central-1.amazonaws.com/src/mus/MagicPony+by+YNKE+Artlist.mp3";
      }
      // return music;
    },
    createAudio() {
      let home = document.getElementById("home");
      let mus = document.createElement("audio");
      mus.className = "mus";
      mus.loop = true;
      // mus.playsinline = true;
      let audio = this.selectAudio();
      console.log(audio);
      mus.src = audio;
      // mus.src =
      //   "https://allpha.s3.ca-central-1.amazonaws.com/src/mus/Camaro+by+OliverMichael+Artlist.mp3";
      // "https://allpha.s3.ca-central-1.amazonaws.com/src/Forever+by+SunnyFruit+Artlist.mp3";
      home.appendChild(mus);
    },
    createVid() {
      let home = document.getElementById("home");
      let vid = document.createElement("video");
      vid.className = "home-video";
      vid.autoplay = true;
      vid.loop = true;
      vid.muted = true;
      vid.setAttribute("playsinline", true);

      var vidSource = document.createElement("source");
      vidSource.type = "video/mp4";
      vidSource.src = this.videoUrl;
      vid.appendChild(vidSource);

      home.appendChild(vid);

      vidSource.onerror = () => {
        console.log(vidSource);
        this.initAnimation();
      };

      vid.addEventListener("canplay", () => {
        // vid.addEventListener("canplaythrough", () => {
        this.initAnimation();
      });
    },
    initAnimation() {
      if (this.animationInitiated === false) {
        let vid = document.getElementsByClassName("home-video")[0];
        let mus = document.getElementsByClassName("mus")[0];
        vid.play();
        this.isAudioPlaying = true;
        mus.volume = 0.025;
        mus.play();
        this.animationInitiated = true;
        setTimeout(() => {
          this.showMenu = true;
        }, 2500);
      }
    }
  },
  computed: {
    videoUrl() {
      let url = "https://allpha.s3.ca-central-1.amazonaws.com/src/Allpha_SD.mp4";
      // let url = "https://allpha.s3.ca-central-1.amazonaws.com/src/Allpha-1.mov";
      // if (window.innerWidth < 900) {
      //   url = "https://allpha.s3.ca-central-1.amazonaws.com/src/Allpha-2.mov";
      // }
      return url;
    }
  },
  watch: {
    pos(newState) {
      console.log(newState, this.infoPos);
      let mus = document.getElementsByClassName("mus")[0];
      if (newState > this.infoPos && this.volumeDowned === false) {
        this.volumeDowned === true;
        mus.volume = 0.01;
      } else if (newState < this.infoPos && this.volumeDowned === true) {
        this.volumeDowned === false;
        mus.volume = 0.025;
      }
    },
    appInitiated(newState) {
      if (newState === true) {
        this.createVid();
        this.createAudio();
        this.infoPos = this.$refs.info.getBoundingClientRect().top;
        this.servPos = this.$refs.service.getBoundingClientRect().top;
        this.teamPos = this.$refs.team.getBoundingClientRect().top;
        this.realisePos = this.$refs.realise.getBoundingClientRect().top;
        this.contPos = this.$refs.contact.getBoundingClientRect().top;
      }
    }
  },
  mounted() {
    let cont = this.$refs.main;

    cont.addEventListener("scroll", () => {
      if (this.ticking === false) {
        setTimeout(() => {
          this.pos = cont.scrollTop;

          this.ticking = false;
        }, 200);

        this.ticking = true;
      }
    });

    if (this.appInitiated === true) {
      this.createAudio();
      this.createVid();
    }
  }
};
</script>
