import { apiCall } from "@/services/api";

export const signUserIn = async data => {
  return apiCall("post", `/auth/signin`,data);
};

export const recoverUserSession = async data => {
  return apiCall("post", `/auth/recover`,data);
};

export const getProjects = async userId => {
  return apiCall("get", `/projects/${userId}/list`);
};

export const getProjectsMedia = async projectId => {
  return apiCall("get", `/projects/${projectId}/media`);
};

export const setNewPassword = async data => {
  return apiCall("post", `/auth/new/cred`, data);
};

export const resetCustomerPassword = async data => {
  return apiCall("put", `/auth/reset-password`, data);
};



export const makeInvoicePayment = async invoiceId => {
  return apiCall("put", `/invoice/pay/${invoiceId}`);
};
