<template>
  <div class="vid-modal">
    <video autoplay playsinline controls>
      <source :src="data.url" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    closeModal: Function
  }
};
</script>
