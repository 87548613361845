<template>
  <div class="contact">

    <h4 @click='goToClientLogIn' class='secret-title'>Accès client</h4>

    <h1>allpha.ca</h1>
    <div class="contact-info">
      <a href="mailto:info@allpha.ca">info@allpha.ca</a>
      <a href="tel:418-802-4227">
        1-418-802-4227
      </a>
    </div>

    <div class="by-axyo">
      <img
        src="https://allpha.s3.ca-central-1.amazonaws.com/src/Logo_light.png"
      />
      <h5>Un service d'AXYO</h5>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  methods: {
    openWriter() {},
    openPhone() {},
    goToClientLogIn(){
      this.$router.push('/portal/signin')
    }
  }
};
</script>
